




















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import ProjectFinanceTable from "@/components/project-finance-table/ProjectFinanceTable.vue";
import Project from "@/entity/Project";
import { ProjectFinanceImportResponse } from "@/entity/ProjectFinance";
import Loader from "@/components/common/Loader.vue";
import ProjectsService from "@/services/ProjectsService";
import UploadIncomeStatementDialog from "@/components/project-finance-table/UploadIncomeStatementDialog.vue";
import MissingDataText from "@/components/common/MissingDataText.vue";
import { moveItemInArray } from "@/utils/array";

@Component({
  components: {
    Loader,
    ProjectFinanceTable,
    UploadIncomeStatementDialog,
    MissingDataText,
  },
})
export default class ProjectFinanceView extends Vue {
  @Ref("upload-income-statement")
  uploadIncomeStatement!: UploadIncomeStatementDialog;
  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ required: true, type: String }) financeActiveTab!: string;

  tab = 0;
  isFetching = false;
  projectFinance: ProjectFinanceImportResponse = {
    balanceSheet: null,
    incomeStatement: null,
    derivedFinancialIndicators: null,
  };

  openIncomeStatementDialog(): void {
    this.uploadIncomeStatement.openDialog();
  }

  get hasData(): boolean {
    return !(
      this.projectFinance.balanceSheet === null &&
      this.projectFinance.incomeStatement === null &&
      this.projectFinance.derivedFinancialIndicators === null
    );
  }

  get balanceSheetData() {
    return [
      ...(this.projectFinance.balanceSheet?.assets ?? []),
      ...(this.projectFinance.balanceSheet?.liabilities ?? []),
    ];
  }

  onUploadSuccess(data: ProjectFinanceImportResponse) {
    this.projectFinance = data;
  }

  async fetchProjectFinance(): Promise<void> {
    try {
      this.isFetching = true;
      const response = await ProjectsService.getBalanceSheet(
        this.project.id as number
      );
      if (response.incomeStatement) {
        const indexOfBruttomargeII =
          response.incomeStatement.parsedData.findIndex(
            (item) => item.variable === "Bruttomarge II"
          );
        const indexOfBruttomarge =
          response.incomeStatement.parsedData.findIndex(
            (item) => item.variable === "Bruttomarge"
          );
        moveItemInArray(
          response.incomeStatement.parsedData,
          indexOfBruttomargeII,
          indexOfBruttomarge + 1
        );
      }
      this.projectFinance = {
        balanceSheet: response.balanceSheet?.parsedData ?? null,
        incomeStatement: response.incomeStatement?.parsedData ?? null,
        derivedFinancialIndicators:
          response.derivedFinancialIndicators?.parsedData ?? null,
      };
    } catch {
      this.$snackbarError(this.$t("apiErrors.unableToLoad"));
    } finally {
      this.isFetching = false;
    }
  }

  tableStyling = {
    incomeStatement: {
      boldRows: [
        "Betriebsertrag",
        "Bruttogewinn",
        "EBITDA",
        "EBIT",
        "EBT",
        "Reingewinn",
      ],
      percentageRows: [
        "Bruttomarge",
        "Bruttomarge II",
        "EBITDA Marge",
        "EBIT Marge",
      ],
      italicRows: [
        "Bruttomarge",
        "Bruttomarge II",
        "EBITDA Marge",
        "EBIT Marge",
      ],
      offsetRows: [
        "Lohnaufwand",
        "Sozialversicherungsaufwand",
        "Übriger Personalaufwand",
        "Unterhalts- und Reparaturaufwand",
        "Fahrzeug- und Transportaufwand",
        "Sachversicherungen und Gebühren",
        "Energie- und Entsorgungsaufwand",
        "Verwaltungs- und Informatikaufwand",
        "Werbeaufwand",
        "Sonstiger Betriebsaufwand",
      ],
    },
    balanceSheet: {
      boldRows: [
        "Total Umlaufvermögen",
        "Total Anlagevermögen",
        "Total Aktiven",
        "Kurzfristiges Fremdkapital",
        "Langfristiges Fremdkapital",
        "Eigenkapital",
        "Total Passiven",
      ],
    },
    derivedIndicators: {
      percentageRows: [
        "Liquiditätsgrad 1",
        "Liquiditätsgrad 2",
        "Liquiditätsgrad 3",
        "Anlagedeckungsgrad 2",
        "Eigenkapitalrendite (ROE)",
        "Umsatzrendite",
        "Verschuldungsgrad",
        "Nettoverschuldungsquote (ND to EBITDA)",
      ],
    },
  };

  created() {
    this.fetchProjectFinance();
  }
}
