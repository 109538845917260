





































import { Component, Prop, Mixins } from "vue-property-decorator";
import ProjectsService from "@/services/ProjectsService";
import RulesMixin from "@/mixins/RulesMixin";
import { EXCEL_FILE_MIME_TYPES } from "@/constants";

@Component
export default class UploadIncomeStatementDialog extends Mixins(RulesMixin) {
  @Prop({ required: true, type: Number }) projectId!: number;
  @Prop({ required: true, type: Boolean }) hasData!: boolean;

  isOpened = false;
  isFormValid = false;
  isUploading = false;
  file: File | null = null;

  async uploadFile(): Promise<void> {
    if (this.file === null) {
      return;
    }

    try {
      if (this.hasData) {
        const confirmResponse = await this.$confirm(
          this.$t("confirmations.balanceSheetReUpload").toString()
        );

        if (!confirmResponse) {
          this.isOpened = false;
          return;
        }
      }

      this.isUploading = true;
      const response = await ProjectsService.uploadBalanceSheet(
        this.projectId,
        this.file
      );
      this.$emit("success", response);
      this.close();
    } catch (error) {
      if (error.response?.data.message) {
        this.$snackbarError(error.response.data.message);
        return;
      }

      this.$snackbarError(this.$tc("apiErrors.unableToUpload"));
    } finally {
      this.isUploading = false;
    }
  }

  openDialog(): void {
    this.isOpened = true;
  }

  get excelFileMimeTypes(): string {
    return EXCEL_FILE_MIME_TYPES.toString();
  }

  close(): void {
    this.isOpened = false;
    (this.$refs.form as any).reset();
  }
}
